import React from 'react';
import { IconButton, Box, Button, Container, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { CustomConnectButton } from './CustomConnectButton';
import logo from './imgs/logo.png';
import { Link } from 'react-router-dom'; // Import Link for routing
import Snackbar from '@mui/material/Snackbar';
import whitepaper from './whitepaper.pdf';


function NavBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  // Define breakpoints
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // Extract common styles for buttons
  const buttonStyles = {
    fontSize: '16px',
    color: '#686868',
    position: 'relative', // Ensure relative positioning for the pseudo-element
    marginLeft: { lg: '60px', sm: '30px', xs: '20px' },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: -5, // Adjust to position below the button
      width: '100%',
      height: '2px', // Height of the underline
      backgroundColor: '#686868', // Underline color
      transform: 'scaleX(0)', // Start scaled down
      transition: 'transform 0.3s ease', // Animation timing
    },
    '&:hover::after': {
      transform: 'scaleX(1)', // Scale to full width on hover
    },
  };  

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    // setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{ width: '100%', height: '100px', display: 'flex', alignItems: 'center' }}>
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          paddingX: isTablet ? '16px' : '0', // paddingX is shorthand for paddingLeft and paddingRight
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message="Coming Up"
        />
        {/* Left: Logo and Title or IconButton */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/" style={{ textDecoration: 'none', height: 40 }}> {/* Link to the home page */}
            <img src={logo} alt="Logo" style={{ width: 40, height: 40 }} />
          </Link>
          {isTablet ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
              sx={{ fontSize: 28, marginLeft: '0px' }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <span style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '12px' }}>
              Laugh Platform
            </span>
          )}
        </Box>

        {/* Right: Navigation and Connect Button */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isTablet && (
            <>
              <Link to="/about" style={{ textDecoration: 'none' }}>
                <Button sx={buttonStyles}>About</Button>
              </Link>
              <Link to="/presale" style={{ textDecoration: 'none' }}>
                <Button sx={buttonStyles} onClick={handleClick}>Presale</Button>
              </Link>
              <Link to="/tokenomic" style={{ textDecoration: 'none' }}>
                <Button sx={buttonStyles}>Tokenomic</Button>
              </Link>
              <a
                href={whitepaper}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Button sx={buttonStyles}>Whitepaper</Button>
              </a>
            </>
          )}
          {/* Always show Connect Button */}
          <Box sx={{ marginLeft: buttonStyles.marginLeft }}>
            <CustomConnectButton />
          </Box>
        </Box>
      </Container>

      {/* Mobile Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} keepMounted>
        <MenuItem onClick={() => { handleMenuClose(); window.location.href = '/about'; }}>
          About
        </MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); window.location.href = '/presale'; }}>
          Presale
        </MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); window.location.href = '/tokenomic'; }}>
          Tokenomic
        </MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); window.open(whitepaper, '_blank'); }}>
          Whitepaper
        </MenuItem>
      </Menu>
    </div>
  );
}

export default NavBar;
