import React from 'react';
import { Modal, Box, Typography, Container, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import presaleLockIcon from '../imgs/presale-lock.png'; // Replace with actual path
import presaleDepositIcon from '../imgs/presale-deposit.png'; // Replace with actual path
import presaleSafeIcon from '../imgs/presale-safe.png'; // Replace with actual path
import presaleModalBg from '../imgs/presale-modal-bg.png'; // Replace with actual path
import { contractConfig } from '../utils';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  position: 'relative'
};

const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '44px',
  padding: '0 16px',
};

const contentStyle = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  marginBottom: '16px',
};

const InfoSection = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${presaleModalBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '8px',
        padding: {
          xs: '20px',  // For mobile screens (small devices)
          sm: '40px'   // For tablets and larger screens
        },
        color: 'white',
        marginTop: '20px',
      }}
    >
      <Typography
        variant="h6"
        sx={{ fontSize: '20px', mb: '16px', color: '#686868', textAlign: 'center' }}
      >
        Total Token Supply
      </Typography>
      <Typography
        variant="h4"
        sx={{ fontSize: '28px', fontWeight: '500', mb: '16px', color: 'black', textAlign: 'center' }}
      >
        1,000,000,000
      </Typography>
      <hr
        style={{
          border: 'none',
          borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          marginBottom: '16px'
        }}
      />
      <Typography
        variant="h6"
        sx={{ fontSize: '20px', mb: '16px', color: '#686868', textAlign: 'center' }}
      >
        Contract Address
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: '16px',
          textDecoration: 'underline',
          color: 'black',
          textAlign: 'center',
          display: 'block',
          wordWrap: 'break-word',
          marginTop: '4px'
        }}
      >
       {contractConfig.tokenAddress} 
      </Typography>
    </Box>
  );
};
const ModalContent = ({ handleClose }) => (
  <Box sx={modalStyle}>
    <Box sx={headerStyle}>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          color: 'black',
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
    <Box sx={contentStyle}>
      <img src={presaleLockIcon} alt="Presale Lock Icon" style={{ width: '24px', height: '24px' }} />
      <Typography variant="body2">
        100% of the funds will be locked in the contract, and the contract owner will not be able to access these funds.
      </Typography>
    </Box>
    <Box sx={contentStyle}>
      <img src={presaleDepositIcon} alt="Presale Deposit Icon" style={{ width: '24px', height: '24px' }} />
      <Typography variant="body2">
        100% of the deposit amount is completely secure and guaranteed for permanent profit.
      </Typography>
    </Box>
    <Box sx={contentStyle}>
      <img src={presaleSafeIcon} alt="Presale Safe Icon" style={{ width: '24px', height: '24px' }} />
      <Typography variant="body2">
        100% fair launch and community-based.
      </Typography>
    </Box>
    <InfoSection />
  </Box>
);

const PresaleModal = ({ open, handleClose }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <ModalContent handleClose={handleClose} />
  </Modal>
);

export default PresaleModal;
