import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import logoBgImage from './imgs/logo-bg.png'; // Center logo image

function Content() {
  const twitter = "https://x.com/LaughPlatform"
  const handleTwitterButtonClick = () => {
    window.open(twitter, '_blank', 'noopener,noreferrer');
  };
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Container maxWidth="lg">
        <Box
          component="img"
          src={logoBgImage}
          alt="Logo"
          sx={{
            width: { xs: '140px', sm: '190px', lg: '240px' },
            height: { xs: '140px', sm: '190px', lg: '240px' },
            mb: { xs: '20px', sm: '30px', lg: '40px' },
          }}
        />
        <Typography
          variant="h1"
          component="div"
          sx={{
            fontSize: { xs: '24px', sm: '40px', lg: '56px' },
            fontWeight: 'bold',
            color: 'black',
            mb: { xs: '10px', sm: '20px', lg: '30px' },
          }}
        >
          Smiles Beyond Borders
        </Typography>
        <Typography
          variant="h2"
          component="div"
          sx={{
            fontSize: { xs: '24px', sm: '40px', lg: '56px' },
            fontWeight: 'bold',
            color: 'black',
            mb: { xs: '20px', sm: '30px', lg: '60px' },
          }}
        >
          Sharing Joyful Moments
        </Typography>
        <Button
          sx={{
            backgroundColor: 'black',
            color: 'white',
            width: { xs: '185px', sm: '215px', lg: '245px' },
            height: '65px',
            borderRadius: '32px',
            fontSize: { xs: '16px', sm: '18px', lg: '20px' },
            fontWeight: 'medium',
            textTransform: 'none',
            '&:hover': { backgroundColor: '#333' },
          }}
          onClick={handleTwitterButtonClick}
        >
            Follow Us
        </Button>
      </Container>
    </Box>
  );
}

export default Content;
