// src/utils.js

import { useAccount, useBalance, useReadContract, useWriteContract } from 'wagmi';
import LFH_IDO_ABI from './constants/LFH_IDO_abi.json'; // Adjust path as necessary


let contractAddress = '0xd681f35c753e4FeFF2c8206Ede48c648f52Fc156'
let tokenAddress = '0x88B9d72670B6f649eB0546429eCAB2063e1AAb1E'
// Contract configuration
export const contractConfig = {
  address: contractAddress,
  tokenAddress: tokenAddress,
  abi: LFH_IDO_ABI,
};

// Custom hook for fetching personal deposit (for a specific user)
export const usePersonalDeposit = () => {
  const { isConnected, address } = useAccount();

  const { data: personalDepositData } = useReadContract({
    ...contractConfig,
    functionName: 'deposits',
    args: [address],
    enabled: isConnected, // Only fetch when connected
  });

  return { personalDepositData, isConnected, address };
};

// Custom hook for fetching total ETH deposit (contract balance)
export const useTotalDeposit = () => {
  const { data: balance } = useBalance({
    address: contractAddress,
  });

  return { totalDepositBalance: balance };
};

// Custom hook for fetching user's ETH balance
export const useUserBalance = () => {
  const { isConnected, address } = useAccount();

  const { data: balance } = useBalance({
    address: isConnected ? address : undefined,
  });

  return { balance, isConnected, address };
};

export const useDeposit = () => {
  const { writeContract } = useWriteContract()
  const result = writeContract({
    ...contractConfig,
    functionName: 'deposit'
  });
  console.log('1111', result)
  return { result };
};

export const useClaimTokens = () => {
  const { write: deposit, error } = useWriteContract({
    ...contractConfig,
    functionName: 'deposit',
  });

  return { deposit, error };
};
