import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import NavBar from '../NavBar';
import Footer from '../Footer';
import './Tokenomic.css';
import tokenTotal from '../imgs/token-total.png';
import tokenSold from '../imgs/token-sold.png';
import tokenLp from '../imgs/token-lp.png';
import tokenCommunity from '../imgs/token-community.png';
import BackgroundContainer from './BackgroundContainer';

const Tokenomic = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg')); // lg corresponds to 1200px

  return (
    <BackgroundContainer>
      <NavBar />
      <div className={`tokenomic-content-wrapper ${isTablet ? 'tablet-layout' : ''}`}>
        <div className="tokenomic-images-container">
          <img src={tokenTotal} alt="Total" className="tokenomic-image token-total" />

          {isTablet ? (
            <>
              <img src={tokenLp} alt="LP" className="tokenomic-image token-lp" />
              <img src={tokenSold} alt="Sold" className="tokenomic-image token-sold" />
            </>
          ) : (
            <>
              <img src={tokenSold} alt="Sold" className="tokenomic-image token-sold" />
              <img src={tokenLp} alt="LP" className="tokenomic-image token-lp" />
            </>
          )}

          <img src={tokenCommunity} alt="Community" className="tokenomic-image token-community" />
        </div>
      </div>
      <Footer className="token-footer" />
    </BackgroundContainer>
  );
};

export default Tokenomic;
