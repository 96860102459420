import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

// Import the images
import AboutImg from './imgs/about.png';
import PresaleImg from './imgs/presale.png';
import TokenomicImg from './imgs/tokenomic.png';
import WhitepaperImg from './imgs/whitepaper.png'; 
import whitepaper from './whitepaper.pdf';

const features = [
  {
    img: AboutImg,
    title: 'About',
    description: 'Learn more about our project and its vision.',
    link: '/about', // Add link for routing
  },
  {
    img: PresaleImg,
    title: 'Presale',
    description: 'Join our presale and be part of the journey.',
    link: '/presale', // Add link for routing
  },
  {
    img: TokenomicImg,
    title: 'Tokenomic',
    description: 'Explore our tokenomics and how it benefits you.',
    link: '/tokenomic', // Add link for routing
  },
  {
    img: WhitepaperImg, // Reuse this image for now
    title: 'Whitepaper',
    description: 'Read our whitepaper to understand the project in-depth.',
    link: whitepaper, // Link to your PDF file
  },
];

function FeatureSection() {
  return (
    <Box sx={{ backgroundColor: '#ffffff', py: 1, mt: '80px', mb: '80px' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {features.map((feature) => (
            <Grid item xs={6} md={3} key={feature.title}>
              {/* If it's the whitepaper, use a normal anchor tag for external link */}
              {feature.title === 'Whitepaper' ? (
                <a href={feature.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component="img"
                      src={feature.img}
                      alt={feature.title}
                      sx={{ width: 120, height: 120, mb: '20px'}}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'medium', fontSize: '24px', color: '#000', mb: '8px' }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#686868', fontSize: '16px' }}>
                      {feature.description}
                    </Typography>
                  </Box>
                </a>
              ) : (
                <Link to={feature.link} style={{ textDecoration: 'none' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component="img"
                      src={feature.img}
                      alt={feature.title}
                      sx={{ width: 120, height: 120, mb: '20px'}}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'medium', fontSize: '24px', color: '#000', mb: '8px' }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#686868', fontSize: '16px' }}>
                      {feature.description}
                    </Typography>
                  </Box>
                </Link>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default FeatureSection;
