import React from "react";
import "./BackgroundContainer.css";
import tokenTopLeft from "../imgs/token-top-left.png";
import tokenTopRight from "../imgs/token-top-right.png";
import tokenBottomLeft from "../imgs/token-bottom-left.png";
import tokenBottomRight from "../imgs/token-bottom-right.png";

const BackgroundContainer = ({ children }) => {
  return (
    <div className="background-container">
      <img
        src={tokenTopLeft}
        alt="Top Left"
        className="token-image token-top-left"
      />
      <img
        src={tokenTopRight}
        alt="Top Right"
        className="token-image token-top-right"
      />
      <img
        src={tokenBottomLeft}
        alt="Bottom Left"
        className="token-image token-bottom-left"
      />
      <img
        src={tokenBottomRight}
        alt="Bottom Right"
        className="token-image token-bottom-right"
      />
      {/* Render any children passed to the BackgroundContainer */}
      {children}
    </div>
  );
};

export default BackgroundContainer;
