import React from 'react';
import { Typography } from '@mui/material';
import NavBar from '../NavBar'; // Ensure this is the correct path
import Footer from '../Footer';
import './About.css';

const About = () => {
  return (
    <div className="about-bg-container">
      <NavBar />
      <div className="about-content-wrapper">
        <Typography variant="h1" sx={{ fontSize: '40px', fontWeight: 'bold', mb: '40px' }}>
          About
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '16px', lineHeight: '40px' }}>
          "Laugh" is a decentralized content-sharing platform focused on humor and entertainment.
          Users can share jokes, funny videos, images, and other entertaining content. The platform allows for interactive features such as commenting, liking, sharing, and reposting content. Users can engage in discussions, vote on their favorite content, and earn tokens for their interactions.
          <br /><br />
          These tokens can be used within the platform, creating a vibrant and rewarding community where humor and entertainment are at the forefront.
        </Typography>
      </div>
      <Footer />
    </div>
  );
};

export default About;
