import React from 'react';
import { Box, Container, Typography, Divider } from '@mui/material';

// Import the Twitter image
import TwitterImg from './imgs/twitter.png';

function Footer() {
  const twitter = "https://x.com/LaughPlatform"
  const handleTwitterButtonClick = () => {
    window.open(twitter, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box sx={{ height: '80px', pt: 3, zIndex: 2 }}>
      <Container maxWidth="lg" sx={{ height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Left side content */}
        <Typography variant="body2" sx={{ fontSize: '14px', color: '#000' }}>
          © 2024 by Laugh. All rights reserved!
        </Typography>
        {/* Right side icon (Twitter) */}
        <Box
          component="a"
          href="https://twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ display: 'inline-block' }}
        >
          <Box
            component="img"
            src={TwitterImg}
            alt="Twitter"
            sx={{ width: '32px', height: '32px' }}
            onClick={handleTwitterButtonClick}
          />
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
