import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import Content from './Content';
import FeatureSection from './FeatureSection'; 

const Home = () => {
  return (
    <>
      <div className="bg-container">
        <div className="navbar">
          <NavBar />
        </div>
        <div className="content-wrapper">
          <Content />
        </div>
      </div>
      <FeatureSection />
      <Footer />
    </>
  );
};

export default Home;
