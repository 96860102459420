import React from 'react';
import Home from './Home'; // 引入 Home.js
import About from './pages/About';
import Presale from './pages/Presale';
import Tokenomic from './pages/Tokenomic';
import Roadmap from './pages/Roadmap';
import './index.css';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Outfit, sans-serif',
  },
});

const config = getDefaultConfig({
  appName: 'My RainbowKit App',
  projectId: '3a8c3a09dc2336bd2eb6150ad075ca4d',
  chains: [mainnet, sepolia],
  ssr: true,
});

const queryClient = new QueryClient();

const App = () => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize="compact" locale="en">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/presale" element={<Presale />} />
                <Route path="/tokenomic" element={<Tokenomic />} />
                <Route path="/roadmap" element={<Roadmap />} />
              </Routes>
            </Router>
          </ThemeProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
