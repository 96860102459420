import React from 'react';

const Roadmap = () => {
  return (
    <div>
      <h1>Roadmap Page</h1>
      <p>This is the Roadmap page.</p>
    </div>
  );
};

export default Roadmap;
