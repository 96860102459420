
import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';
import { Button, Container, TextField } from '@mui/material';
import presaleInfo from '../imgs/presale-info.png';
import presaleLogo from '../imgs/presale-logo.png';
import PresaleModal from './PresaleModal'; // Import the Modal component
import './Presale.css';
import { contractConfig } from '../utils'; // Import your custom hooks
import { formatUnits } from 'viem';
import Web3 from 'web3';
import { useAccount } from 'wagmi';

const Presale = () => {
  const [open, setOpen] = useState(false);
  const [isPending, setPending] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [web3, setWeb3] = useState(null);
  const [personalDeposit, setPersonalDeposit] = useState(0);
  const [personalDepositToken, setPersonalDepositToken] = useState(0);
  const [personalClaimed, setPersonalClaimed] = useState(false);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState('');

  const { isConnected, address } = useAccount();

  useEffect(() => {
    if (window.ethereum && isConnected) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      window.ethereum.request({ method: 'eth_requestAccounts' })
    }
  }, [isConnected]);

  const fetchPersonalDeposit = async () => {
    if (web3 && address) {
      console.log(web3.eth)
      const contract = new web3.eth.Contract(contractConfig.abi, contractConfig.address);
      const depositData = await contract.methods.deposits(address).call();
      setPersonalDeposit(formatUnits(depositData[0], 18));
      setPersonalDepositToken(formatUnits(depositData[1], 18))
      setPersonalClaimed(depositData[2])
    }
  };

  const fetchTotalDeposit = async () => {
    if (web3) {
      const balance = await web3.eth.getBalance(contractConfig.address);
      setTotalDeposit(formatUnits(balance, 18));
    }
  };

  const fetchUserBalance = async () => {
    if (web3 && address) {
      const userBalance = await web3.eth.getBalance(address);
      setBalance(formatUnits(userBalance, 18));
    }
  };

  const [claimEnabled, setClaimEnabled] = useState(false)
  const fetchClaimEnabled = async () => {
    if (web3) {
      const contract = new web3.eth.Contract(contractConfig.abi, contractConfig.address);
      const claimEnabled = await contract.methods.claimEnabled().call()
      setClaimEnabled(claimEnabled);
    }
  };

  const refresh = () => {
    fetchPersonalDeposit()
    fetchTotalDeposit()
    fetchUserBalance()
    fetchClaimEnabled()
  }

  useEffect(() => {
    refresh()
  }, [web3, isConnected, address]);

  const [helperText, setHelperText] = useState('');

  const targetDate = new Date('2024-10-11T08:00:00Z');

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const difference = +targetDate - +new Date();
    let timeLeft = {};
  
    if (difference > 0) {
      const totalSeconds = Math.floor(difference / 1000);
      const days = Math.floor(totalSeconds / (3600 * 24));
      const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
  
      timeLeft = {
        days,
        hours,
        minutes,
        seconds
      };
    }
  
    return timeLeft;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTimeLeft = () => {
    const { days, hours, minutes, seconds } = timeLeft;
    
    const pad = (num) => String(num).padStart(2, '0');
  
    const formattedDays = pad(days || 0);
    const formattedHours = pad(hours || 0);
    const formattedMinutes = pad(minutes || 0);
    const formattedSeconds = pad(seconds || 0);
  
    return `${formattedDays}d ${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
  };

  const handleDeposit = async () => {
    if (web3 && address) {
      setPending(true);
      setHelperText('');
      try {
        const maxDeposit = 1; // Maximum deposit limit
        const availableDeposit = maxDeposit - personalDeposit; // Calculate available deposit
        const inputAmount = parseFloat(amount);
        if (!amount || inputAmount < 0.1 || inputAmount > availableDeposit) {
          setHelperText(`Amount must be between 0.1 ETH and your available limit of ${availableDeposit.toFixed(2)} ETH`);
          setPending(false);
          return;
        }

        const contract = new web3.eth.Contract(contractConfig.abi, contractConfig.address);
        await contract.methods.deposit().send({
          from: address,
          value: web3.utils.toWei(amount, 'ether'),
        });
        refresh()
      } catch (error) {
        setHelperText('Transaction failed. Please try again.');
        console.error("Transaction failed:", error);
      } finally {
        setPending(false);
      }
    }
  };

  const handleClaimTokens = async () => {
    if (web3 && address) {
      setPending(true)
      try {
        const contract = new web3.eth.Contract(contractConfig.abi, contractConfig.address);
        await contract.methods.claimTokens().send({ from: address });
        // Optionally, refresh deposits or balance here if needed
        refresh()
      } catch (error) {
        console.error("Transaction failed:", error);
      } finally {
        setPending(false);
      }
    }
  };

  // Input change handler
  const handleAmountChange = (e) => {
    const value = e.target.value;

    // Check if input is a valid number (allowing decimal point)
    const validInputPattern = /^\d*\.?\d*$/;

    // If the input matches the valid pattern, set the amount
    if (validInputPattern.test(value)) {
      setAmount(value);
    }
  };

  return (
    <div className="presale-bg-container">
      <NavBar />
      <div className="presale-content-wrapper">
        <Container
          maxWidth="md"
          disableGutters
          sx={{ backgroundColor: 'white', borderRadius: '20px', padding: { xs: '32px 16px', md: '40px' } }}
        >
          {/* First Section */}
          <div className="first-section">
            <div className="text-group">
              <div className="label">
                Personal Deposit
              </div>
              <div className="value">
                {personalDeposit} ETH
              </div>
            </div>
            <div className="text-group">
              <div className="label">
                Total Deposit
              </div>
              <div className="value">
                {totalDeposit} ETH
              </div>
            </div>
            <div className="button-wrapper">
              <Button
                variant="text"
                onClick={handleOpen}
                sx={{
                  color: 'black',
                  fontSize: '16px',
                  textTransform: 'none',
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <img
                  src={presaleInfo}
                  alt="Info Icon"
                  style={{ width: '24px', height: '24px', marginRight: '8px' }}
                />
                More
              </Button>
            </div>
          </div>

          {/* Second Section */}
          <div className="second-section">
            <img
              src={presaleLogo}
              alt="Presale Logo"
            />
            <div className="token-info">
              <div className="token-name">
                LFT
              </div>
              <div className="token-description">
                Laugh Platform Token
              </div>
              <div className="token-details">
                <span>Min: 0.1 ETH</span>
                <span style={{ marginLeft: '20px' }}>Max: 1 ETH</span>
              </div>
            </div>
          </div>

          {/* Third Section */}
          <div className="third-section">
            <div className="balance-info">
              <div className="balance-label">Balance:</div>
              <div className="balance-value">
                {balance}
              </div>
              <div className="max-label">ETH</div>
            </div>

            <div className="token-amount-info">
              <div className="token-amount-label">Tokens to be claimed:</div>
              <div className="token-amount-value">
                {personalDepositToken}
              </div>
            </div>

            {
              Object.keys(timeLeft).length > 0 ? (
                <Button
                  variant="contained"
                  disabled
                  sx={{
                    height: '60px',
                    backgroundColor: 'gray',
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: '500',
                    textTransform: 'none',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundColor: 'gray',
                    },
                    marginTop: '20px'
                  }}
                >
                  {formatTimeLeft()}
                </Button>
              ) : (
                claimEnabled ?
                  <Button
                    variant="contained"
                    onClick={handleClaimTokens}
                    disabled={isPending || !address || personalClaimed || personalDepositToken <= 0}
                    sx={{
                      height: '60px',
                      backgroundColor: 'black',
                      color: 'white',
                      fontSize: '16px',
                      fontWeight: '500',
                      textTransform: 'none',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    {personalClaimed ? "You have claimed" : (isPending ? "Confirming..." : "Claim Tokens")}
                  </Button>
                  :
                  <>
                    <TextField
                      variant="outlined"
                      placeholder="Enter amount"
                      className="textfield-container"
                      value={amount}
                      onChange={handleAmountChange}
                      FormHelperTextProps={{ sx: { color: 'red' } }}
                      helperText={helperText}
                      InputProps={{
                        endAdornment: (
                          <div style={{ fontSize: '16px', color: 'black' }}>ETH</div>
                        ),
                      }}
                    />

                    <Button
                      variant="contained"
                      onClick={handleDeposit}
                      disabled={isPending || !address}
                      sx={{
                        height: '60px',
                        backgroundColor: 'black',
                        color: 'white',
                        fontSize: '16px',
                        fontWeight: '500',
                        textTransform: 'none',
                        borderRadius: '8px',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                        marginTop: '20px'
                      }}
                    >
                      {isPending ? "Confirming..." : "Buy with ETH"}
                    </Button>
                  </>
              )
            }
          </div>
        </Container>
      </div>
      <Footer />
      <PresaleModal open={open} handleClose={handleClose} /> {/* Add Modal component */}
    </div>
  );
};

export default Presale;
